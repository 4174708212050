import React, { useState } from "react";
import {
  Alert,
  Card,
  Col,
  Container,
  Row,
  Table,
  Button,
} from "react-bootstrap";
import { Collapse } from "@material-ui/core";
import LocationPhoneNumber from "./LocationPhoneNumber/LocationPhoneNumber";
import SoarianDetails from "./SoarianDetails/SoarianDetails";
import { Icon, InlineIcon } from "@iconify/react";
import chevronThinDown from "@iconify-icons/entypo/chevron-thin-down";
import chevronThinUp from "@iconify-icons/entypo/chevron-thin-up";
import "./LocationCard.css";

function LocationCard(props) {
  const [showText, setshowText] = useState(false);
  // const [options2, setoptions] = useState(options);
  //   console.log("here", props);
  const status = props.location.status === "active" ? "Active" : "Inactive";
  //   console.log("here", status);
  //const options = [];
  let expiration_date = props.location.expiration_date;
  if (expiration_date !== null && expiration_date !== undefined) {
    const milliseconds = expiration_date * 1000; // 1575909015000
    const date = new Date(milliseconds);
    const month = date.toLocaleString("en-US", { month: "long" });

    expiration_date = month + " " + date.getDate() + ", " + date.getFullYear();
  }

  const is_primary = props.location.location_type === "P" ? "Yes" : "No";
  const is_online_scheduling = props.location.is_online_scheduling
    ? "Yes"
    : "No";
  const is_online_rescheduling = props.location.is_online_rescheduling
    ? "Yes"
    : "No";

  let location_source = "Unknown";
  switch (props.location.source) {
    case "newport":
      location_source = "Provider Enrollment (Newport/Tract)";
      break;

    case "echo":
      location_source = "Credentialling (ECHO)";
      break;

    case "soarian":
      location_source = "Scheduling (Soarian)";
      break;

    default:
      break;
  }

  let practiceName = (
    <div className="location-practice-name location-data-section first">
      <h3>Practice Name</h3>
      <Alert variant="warning">No practice name available</Alert>
    </div>
  );
  if (props.location.practice_name !== null) {
    practiceName = (
      <div className="location-practice-name location-data-section first">
        <h3>Practice Name</h3>
        <span>{props.location.practice_name}</span>
      </div>
    );
  }

  let latLngDisplay = "Not available";
  if (props.location.latitude !== null && props.location.longitude !== null) {
    latLngDisplay = props.location.latitude + ", " + props.location.longitude;
  }
  //let collapse = "Collapse" + <FontAwesomeIcon icon={faPhone} />;

  return (
    <Card>
      <Card.Body>
        <Container>
          <Row>
            <Col md={3} md-offset={1}>
              {practiceName}
            </Col>

            <Col md={4}>
              <div className="location-address location-data-section first">
                <h3>Physical Address</h3>
                <span>{props.location.street_address}</span>
                <br />
                {props.location.suite !== null ? (
                  <React.Fragment>
                    <span>{props.location.suite}</span>
                    <br />
                  </React.Fragment>
                ) : null}
                <span>{props.location.city}</span>,{" "}
                <span>{props.location.state}</span>{" "}
                <span>{props.location.zip}</span>
              </div>
            </Col>
            <Col md={2}>
              <div className="location-practice-name location-data-section first">
                <h3>Status</h3>
                <span>{status}</span>
              </div>
            </Col>
            <Col md={2}>
              <div className="location-practice-name location-data-section first">
                <h3>Termination date</h3>
                <span>{expiration_date}</span>
              </div>
            </Col>
            <Col md={1}>
              <Button
                className="ButtonLink"
                onClick={() => setshowText(!showText)}
              >
                {" "}
                {showText === false ? "Expand" : "Collapse"}
              </Button>
              {showText === false ? (
                <Icon
                  icon={chevronThinDown}
                  color="#007BC2"
                  width="16"
                  height="8.68"
                />
              ) : (
                <Icon
                  icon={chevronThinUp}
                  color="#007BC2"
                  width="16"
                  height="8.68"
                />
              )}
            </Col>
          </Row>

          <Collapse in={showText}>
            <Row>
              <Col sm={4}></Col>
              <Col sm={8}>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <SoarianDetails location={props.location} />
              </Col>
              <Col sm={4}>
                <div className="location-contacts location-data-section">
                  <h3>Practice Contact Numbers</h3>
                  <LocationPhoneNumber
                    label="Phone"
                    number={props.location.phone}
                    link
                  />
                  <LocationPhoneNumber
                    label="Fax"
                    number={props.location.fax}
                  />
                </div>
              </Col>
              <Col sm={4}>
                <Table
                  bordered
                  className="location-contacts location-data-section"
                >
                  <thead>
                    <tr>
                      <th>Property</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Primary Location</td>
                      <td>
                        <strong>{is_primary}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Source System</td>
                      <td>
                        <strong>{location_source}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Latitude / Longitude</td>
                      <td>
                        <strong>{latLngDisplay}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Google Id</td>
                      <td>
                        <strong>{props.location.google_places_id}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Bookable online?</td>
                      <td>
                        <strong>{is_online_scheduling}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Appointments can be rescheduled online?</td>
                      <td>
                        <strong>{is_online_rescheduling}</strong>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Collapse>

          {/*  */}
        </Container>
      </Card.Body>
    </Card>
  );
}

export default LocationCard;
